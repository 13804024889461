body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page-wrapper .via-admin-text {
  padding-top: 7vh;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
  .home-page-wrapper .home-page .via-admin-text {
    padding-top: 0vh;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner0 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner0 .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #fff;
  width: 550px;
}
.banner0 .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner0 .banner0-title {
  width: 350px;
  left: 30px;
  min-height: 60px;
  margin: auto;
  display: inline-block;
  font-size: 40px;
  position: relative;
}
.banner0 .banner0-content {
  margin-bottom: 20px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner0 .banner0-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 16px;
  height: 40px;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.banner0 .banner0-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner0 .banner0-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner0 .banner0-button.queue-anim-leaving {
  width: auto;
}
.banner0 .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #43946c;
}
@media screen and (max-width: 767px) {
  .banner0 {
    background-attachment: inherit;
  }
  .banner0 .banner0-text-wrapper {
    width: 90%;
  }
  .banner0 .banner0-title {
    width: 90%;
    left: 0;
  }
}
.teams0-wrapper {
  height: 430px;
}
.teams0-wrapper .teams0 {
  padding: 48px 24px;
  display: flex;
  align-items: flex-end;
}
.teams0-wrapper .teams0 .banner-anim {
  width: 100%;
  height: 100%;
}
.teams0-wrapper .teams0 .banner-anim-thumb span {
  background: #e9e9e9;
  box-shadow: none;
}
.teams0-wrapper .teams0 .banner-anim-thumb span.active {
  background: #1890ff;
}
.teams0-wrapper .teams0 .queue-anim-leaving {
  position: relative !important;
}
.teams0-wrapper .teams0-banner-user-elem {
  height: 100%;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.teams0-wrapper .teams0-image {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.teams0-wrapper .teams0-image img {
  height: 100%;
}
.teams0-wrapper .teams0-content {
  font-size: 12px;
  color: #919191;
  text-align: center;
  width: 80%;
  margin: 8px auto;
}
.teams0-wrapper .teams0-h1 {
  font-size: 24px;
  text-align: center;
  width: 80%;
  margin: 24px auto 0;
}
@media screen and (max-width: 767px) {
  .teams0-wrapper {
    min-height: 480px;
  }
  .teams0-wrapper .teams0 {
    display: block;
  }
}
.content2-wrapper {
  height: 360px;
}
.content2-wrapper .content2 {
  height: 100%;
  padding: 0 24px;
}
.content2-wrapper .content2-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content2-wrapper .content2-img span {
  display: block;
  width: 250px;
}
.content2-wrapper .content2-img span img {
  display: block;
}
.content2-wrapper .content2-text {
  padding: 0 32px;
  height: 100%;
}
.content2-wrapper .content2-text .content2-content,
.content2-wrapper .content2-text .content2-title {
  position: relative !important;
}
.content2-wrapper .content2-text .content2-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content2-wrapper .content2-text .content2-content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content2-wrapper {
    height: 600px;
  }
  .content2-wrapper .content2-img {
    height: 200px;
    padding: 0;
    text-align: center;
  }
  .content2-wrapper .content2-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content2-wrapper .content2-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content2-wrapper .content2-text .content2-content,
  .content2-wrapper .content2-text .content2-title {
    width: 100%;
    top: auto;
  }
  .content2-wrapper .content2-text .content2-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content1-wrapper {
  height: 420px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content9-wrapper {
  padding-bottom: 64px;
}
.content9-wrapper.home-page-wrapper {
  overflow: initial;
}
.content9-wrapper .content9 {
  min-height: 800px;
  padding: 64px 0 0;
}
.content9-wrapper .timeline {
  position: relative;
}
.content9-wrapper .timeline:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: calc(100% - 108px);
  margin: 34px 0;
  border-left: 2px #ebedf0 dashed;
}
.content9-wrapper .block-wrapper {
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  position: relative;
  margin-bottom: 70px;
  min-height: 160px;
}
.content9-wrapper .block-wrapper:last-child {
  margin-bottom: 0;
}
.content9-wrapper .image-wrapper,
.content9-wrapper .text-wrapper {
  width: 50%;
}
.content9-wrapper .image-wrapper {
  text-align: center;
}
.content9-wrapper .image-wrapper .block-img,
.content9-wrapper .image-wrapper .name-wrapper {
  float: right;
  clear: both;
  width: 262px;
  margin: auto;
}
.content9-wrapper .image-wrapper .block-img {
  height: 98px;
}
.content9-wrapper .image-wrapper .block-img img {
  height: 100%;
}
.content9-wrapper .image-wrapper .name-wrapper {
  margin: 16px 0 0;
}
.content9-wrapper .image-wrapper .name-wrapper .block-name {
  font-size: 14px;
  margin-bottom: 4px;
}
.content9-wrapper .image-wrapper .name-wrapper .block-post {
  font-size: 12px;
  color: #697b8c;
}
.content9-wrapper .text-wrapper {
  padding-left: 40px;
}
.content9-wrapper .text-wrapper .block-time {
  font-size: 12px;
  color: #697b8c;
  line-height: 18px;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-title {
  margin: 8px 0 12px;
  font-size: 14px;
  position: relative;
  min-height: 18px;
}
.content9-wrapper .text-wrapper .block-icon {
  position: absolute;
  left: -40px;
  transform: translateX(calc(-50% + 1px));
}
.content9-wrapper .text-wrapper .block-content {
  width: 300px;
  color: #314659;
  font-size: 12px;
  min-height: 18px;
}
@media screen and (max-width: 767px) {
  .content9-wrapper {
    padding-bottom: 0;
  }
  .content9-wrapper .content9 {
    padding: 64px 24px;
  }
  .content9-wrapper .timeline:before {
    left: 0;
    height: 100%;
    margin: 0;
  }
  .content9-wrapper .block-wrapper {
    display: block;
    min-height: 100px;
    margin-bottom: 54px;
  }
  .content9-wrapper .image-wrapper,
  .content9-wrapper .text-wrapper {
    width: 100%;
  }
  .content9-wrapper .image-wrapper .block-img {
    display: none;
  }
  .content9-wrapper .image-wrapper .name-wrapper {
    text-align: left;
    float: left;
    width: 100%;
    padding-left: 40px;
    margin: auto;
  }
  .content9-wrapper .image-wrapper .block-name,
  .content9-wrapper .image-wrapper .block-post {
    display: inline-block;
  }
  .content9-wrapper .image-wrapper .block-name {
    margin-right: 8px;
  }
  .content9-wrapper .text-wrapper .block-content {
    display: none;
  }
  .content9-wrapper .text-wrapper .block-title {
    font-size: 16px;
  }
}
.content0-wrapper {
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 16px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0 .description-feature00 {
  text-align: center;
  margin: 0 120px;
  padding-top: 30px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 100%;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
.content0-wrapper .content0-block-contact-tanos {
  margin-top: 10px;
  position: absolute;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 180px;
  }
}
.content11-wrapper {
  height: 630px;
  background: url("https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg") no-repeat bottom;
  background-size: cover;
  background-size: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 40px;
}
.content11-wrapper.home-page-wrapper .title-wrapper {
  margin-bottom: 32px;
}
.content11-wrapper .button {
  box-shadow: 0 8px 16px #0554b7;
  background: linear-gradient(to right, #05cbff, #1e5aff) !important;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  border: 0;
  border-radius: 21px;
  color: #fff;
  width: 128px;
  padding: 0 15px;
  display: inline-block;
  transition: transform 0.3s, box-shadow 0.3s;
}
.content11-wrapper .button:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 20px #0554b7;
}
.content11-wrapper .button:active {
  transform: translateY(4px);
  box-shadow: 0 4px 8px #0554b7;
}
.content11-wrapper .title-content {
  line-height: 32px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .content11-wrapper {
    padding-bottom: 0;
    height: 700px;
  }
  .title-content {
    margin: 16px;
  }
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #1890ff;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}
#Content9_0 .block-wrapper > .text-wrapper > .l57g6047k2d-editor_css {
  text-align: justify;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57hqtkvppt-editor_css {
  text-align: justify;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57hv2u0zr-editor_css {
  text-align: justify;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57hzos84uc-editor_css {
  text-align: justify;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57i1tmdjkn-editor_css {
  text-align: justify;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57kemdn3mo-editor_css {
  font-weight: bold;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57fi2w6m18-editor_css {
  text-align: justify;
}
#Content9_0 .timeline > .block-wrapper > .l57g4e4xm9-editor_css {
  background-clip: padding-box;
}
#Content9_0 .home-page > .l57ffwsdawo-editor_css {
  background-clip: padding-box;
}
#Content9_0 .home-page > .timeline > .l57l1erixwp-editor_css {
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0);
  margin: 0px 0px 16px;
}
#Content9_0 .home-page > .timeline > .l57lp3kb9cc-editor_css {
  margin: 0px 0px 16px;
}
#Content9_0 .home-page > .timeline > .l57lpexuoy-editor_css {
  margin: 0px 0px 16px;
}
#Content9_0 .home-page > .timeline > .l57lpm8q1xb-editor_css {
  margin: 0px 0px 16px;
}
#Content9_0 .home-page > .timeline > .l57lpsie94f-editor_css {
  margin: 0px 0px 16px;
}
#Content11_1 .title-wrapper > .l57m7r6nrnf-editor_css {
  margin-top: 16px;
}
#Footer1_0 .footer1 > .ant-row > .l57m8fdv4gq-editor_css {
  color: #314659;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#Footer1_0 .footer1 > .ant-row > .l57m97g28ge-editor_css {
  color: #314659;
}
#Footer1_0 .footer1 > .ant-row > .l57m9vag6uh-editor_css {
  color: #314659;
}
#Content11_1 .title-wrapper > .l57m8a95e9j-editor_css {
  display: inline-block;
  justify-content: center;
  max-width: 600px;
}
#Nav3_0 .header3-menu > .ant-menu > .l59pl5lznuf-editor_css {
  display: none;
}
#Content9_0 .home-page > .timeline > .l57i9da2lwh-editor_css {
  background-color: rgba(0, 0, 0, 0);
  margin: 0px 0px 16px;
}
#Content9_0 .home-page > .timeline > .l57ffbuuwmt-editor_css {
  background-color: rgba(0, 0, 0, 0);
  margin: 0px 0px 24px;
}
#Content9_0 .l57ffhdd5gt-editor_css {
  padding: 32px 0px 0px;
}
.ant-btn {
  background: #43946c !important;
  color: white;
}
.ant-btn:hover,
.ant-btn:focus {
  color: white;
  border-color: white;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: none;
}
@media screen and (max-width: 767px) {
  #Content9_0 .l57ffhdd5gt-editor_css {
    padding: 64px 24px 32px;
  }
}
#Content9_0.l57ffz3eoz-editor_css {
  padding: 0 0 16px;
}
#Footer1_0 .ant-row > .ant-col > .l59tb1ubz7j-editor_css {
  padding: 0px 0px 12px;
}
#Teams0_0 .banner-anim > .banner-anim-elem > .l65vop664te-editor_css {
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}
#Nav3_0 .l59q16780sh-editor_css {
  width: 100%;
}
#Content9_0 .home-page > .title-wrapper > .l65xh9oluba-editor_css {
  margin: 0 0 8px;
}
#Content9_0 .home-page > .title-wrapper > .l65xcxbh5s-editor_css {
  margin-top: 16px;
}
#Teams0_0 .l65tz9aaqad-editor_css {
  background-clip: padding-box;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}
#Content9_0 .block-wrapper > .image-wrapper > .l65yl36oyoj-editor_css {
  font-size: 12px;
}
#Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l65wa7b12i6-editor_css {
  background-color: #00a854;
}
#Content11_1 .l57mblpsdmf-editor_css {
  margin: 0px 0 32px;
}
#Content11_1.l65w3sp2e8-editor_css {
  background-image: url();
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
}
#Content9_0 .block-wrapper > .text-wrapper > .l57kehryrr8-editor_css {
  font-weight: bold;
}
#Content9_0 .block-wrapper > .text-wrapper > .l660caty2kp-editor_css {
  font-weight: bold;
}
#Teams0_0.l65vwm0wumd-editor_css {
  background-clip: padding-box;
}
#Feature2_0 .ant-row > .l65vthowlkh-editor_css {
  justify-content: center;
}
#Feature1_0 .ant-row > .ant-col > .l660yl2kgxe-editor_css {
  text-align: center;
}
#Feature2_0 .ant-row > .ant-col > .l660z6e9jq-editor_css {
  text-align: center;
}
#Feature2_0 .ant-row > .ant-col > .l65vtggv2o7-editor_css {
  width: 360px;
}
#Banner0_0 .banner0-text-wrapper > .l66221er87j-editor_css {
  display: none;
}
#Banner0_0 .banner0-text-wrapper > .l662244en4-editor_css {
  display: none;
}
#Banner0_0.l66237937o-editor_css {
  background-image: url("https://i.im.ge/2022/07/29/FmHROq.jpg");
  background-size: contain;
}
#Banner0_0 .banner0-text-wrapper > .l6621xc87fo-editor_css {
  display: none;
  background-color: #222222;
}
#Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l65w7fx38fg-editor_css {
  color: #222222;
  margin: 0px auto 8px;
}
#Nav3_0.l59q48ajj1-editor_css {
  background-color: #43946c;
}
#Footer1_0.l57m0kkeea-editor_css {
  background-color: #43946c;
}
#Footer1_0 .copyright-wrapper > .home-page > .l57m0rpsrl-editor_css {
  font-weight: bold;
  color: #fbfbfb;
}
#Footer1_0 .ant-col > .slogan > .l57m522ytw-editor_css {
  font-size: 14px;
  color: #d9d9d9;
  text-align: center;
}
#Footer1_0 .ant-col > div > .l6630915e1-editor_css {
  color: #d9d9d9;
  text-align: justify;
  margin: 0px 0px 16px;
}
#Footer1_0 .ant-row > .ant-col > .l57m7yino6g-editor_css {
  color: #404040;
}
#Footer1_0 .ant-row > .ant-col > .l57m85qbe6-editor_css {
  color: #404040;
}
#Footer1_0 .ant-row > .ant-col > .l57m828mba-editor_css {
  color: #404040;
}
#Footer1_0 .footer1 > .l57m0ifizd-editor_css {
  padding: 64px 24px 32px;
}
@media screen and (max-width: 767px) {
  #Footer1_0 .footer1 > .l57m0ifizd-editor_css {
    padding: 32px 24px;
  }
}
#Footer1_0 .ant-col > div > .l663xxcz3v8-editor_css {
  color: #007bff;
  background-color: #fbfbfb;
}
#Footer1_0 .ant-col > div > .l663zyd6id-editor_css {
  color: #007bff;
}
#Footer1_0 .ant-col > div > .l57m8ef96b-editor_css {
  color: #d9d9d9;
  margin-bottom: 12px;
  text-align: center;
}
#Footer1_0 .ant-col > div > .l662w6x64t-editor_css {
  color: #d9d9d9;
}
#Footer1_0 .ant-col > div > .l663krc44cm-editor_css {
  color: #d9d9d9;
  text-align: center;
}
#Footer1_0 .ant-col > div > .l663f86vzr-editor_css {
  color: #d9d9d9;
  display: flex;
  flex-direction: column;
}
#Footer1_0 .ant-col > div > .l663mkvd54-editor_css {
  color: #d9d9d9;
  text-align: center;
  margin: 0px 0px 8px;
}
#Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l57jjr8nctn-editor_css {
  font-family: "Times New Roman";
  font-style: italic;
  font-weight: bold;
  font-size: 18px;
  color: #222222;
}
@media screen and (max-width: 767px) {
  #Teams0_0 .banner-anim-elem > .teams0-content-wrapper > .l57jjr8nctn-editor_css {
    font-size: 16px;
  }
}
#Feature0_0 .home-page > div > .l6q99624ah-editor_css {
  justify-content: center;
}
#Feature0_0 .ant-col > .content0-block-item > .l6q99jh2dbo-editor_css {
  width: 100px;
}
#Footer1_0 .ant-col > .l6qa25dhoeo-editor_css > .l663mkvd54-editor_css {
  text-align: center;
}

